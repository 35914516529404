import Link from 'next/link';

import { format, parseISO } from 'date-fns';

import styles from './FloatingCart.module.scss';

import { CartAPI } from '../../../api/CartAPI';
import { CartContext } from '../../../contexts/CartContext';
import { ABContext } from '../../../contexts/ABContext';
import { CartTicket, CartType } from '../../../types/CartType';
import { groupCartTicketsByCategoryAndProductAndDate, cartTicketCategories } from '../../../utils/cartUtils';
import { cartGuestNamePrularizer } from '../../../utils/namePluralizer';
import { useContext, useEffect, useState } from 'react';
import { Hotel_SlugContext, Hotel_SlugContextType } from '../../../contexts/Hotel_SlugContext';
import { HotelSearchContext, HotelSearchContextType } from '../../../contexts/HotelSearchContext';
import { HotelSearchRoom } from '../../../types/HotelType';
import { useRouter } from 'next/router';
import FloatingCartPopUp from './FloatingCartPopUp/FloatingCartPopUp';

// CRO-1235
type Clicked = {
  click: boolean,
  section: string
}

export const ticketSections = [
  // Remove Extras page as there was nothing on it
  { id: 'hotels', path: '/hotels', previousSection: null, nextSection: 'tickets', title: 'Hotels', nextText: 'Next, choose Rooms'},
  { id: 'rooms', path: '/hotels', previousSection: 'hotels', nextSection: 'tickets', title: 'Rooms' , nextText: 'Next, view Tickets'},
  { id: 'tickets', path: '/park-tickets', previousSection: 'hotels', nextSection: 'expressPasses', title: 'Tickets', nextText: 'Next, view Express Passes'},
  { id: 'expressPasses', path: '/express-pass', previousSection: 'tickets', nextSection: 'cart', title: 'Express Passes', nextText: 'Next, view your Cart'},
  { id: 'cart', path: '/shopping-cart', previousSection: 'expressPasses', nextSection: null, title: 'Cart', nextText: 'Check out!'},
]

type FloatingCartProps = {
  currentSection: string,
  showNextStep?: boolean,
  showCartPopUp?: boolean,
  placement: string,
}

function getQtyString(tickets: CartTicket[]): string {
  const numAdult = tickets.find(t => t.data.type_ticket === 'Adult')?.qty ?? 0
  const numChild = tickets.find(t => t.data.type_ticket === 'Child')?.qty ?? 0
  const numGeneral = tickets.find(t => t.data.type_ticket === 'General')?.qty ?? 0
  const strings: string[] = []
  if (numAdult) strings.push(`${numAdult} Adult`)
  if (numChild) strings.push(`${numChild} Child`)
  if (numGeneral) strings.push(`${numGeneral} All Ages`)
  return strings.join(', ')
}

function removeLink(cartAPI: CartAPI | undefined, ticketIds: Array<string>, dateOrYearStr?: string) {
  return cartAPI ? <a className={ styles.removeLink } onClick={() => cartAPI.removeAll(ticketIds, dateOrYearStr)}>REMOVE</a> : null
}

// CRO-1235 props: isCro1235, clicked, setClicked
function sectionHeader(theTicketSections: Array<any>, sectionId: string, currentSectionId: string, sectionHasItemsInBasket: boolean, currentSectionCatgory: any, setCurrentSectionCatgory: any, isCro1235: boolean, clicked: Clicked, setClicked:(args:Clicked) => void): JSX.Element | null {

  const section = theTicketSections.find(s => s.id === sectionId);

  if (section) { // CRO-1235 checking if section title is clicked and adding a spinner to it while the page loads
    const title = <strong className={`${styles.rowTitle} ${isCro1235 && clicked.click && section.id === clicked.section && clicked.section !== currentSectionId && styles.cro1235Loading}`}>{ section.title }</strong>
    let linkClass = `${styles.link}`;
    if (sectionHasItemsInBasket) {
      linkClass = `${styles.link} ${styles.complete}`;
    }
    // if (sectionId === currentSectionId) return title
    return <>
      <Link
        href={ section.path }
        className={linkClass}
        onClick={() => isCro1235 && setClicked({click: true, section: section.id})}
        >
        { title }
      </Link>
      { sectionHasItemsInBasket ? <a onClick={() => setCurrentSectionCatgory(currentSectionCatgory === section.id ? null : section.id)} className={ styles.tick }></a> : null }
    </>;
  }
  return null
}

export function FloatingCart({ showNextStep, currentSection, showCartPopUp, placement }: FloatingCartProps) {
  const ab = useContext(ABContext);

  // CRO-1235
  const isCro1235 = ab.isActiveTest('CRO-1235-Display-full-desktop-basket');
  const [clicked, setClicked] = useState<Clicked>({click: false, section: currentSection});
  const [hasHotels, setHasHotels] = useState(false);

  const {route} = useRouter();
  const { hotelSearchRooms } = useContext<HotelSearchContextType>(HotelSearchContext);
  const { hotel_slugToContext } = useContext<Hotel_SlugContextType>(Hotel_SlugContext);
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [loadingLink, setLoadingLink] = useState<boolean>(false)
  const [currentSectionCatgory, setCurrentSectionCatgory] = useState<string>('')
  const [roomSearchLength, setRoomSearchLength] = useState<number>(0)

  const theTicketSections = ticketSections;
  const nextButton = 'Continue';

  const nextStepId = theTicketSections.find(s => s.id === currentSection)?.nextSection;
  const nextStepPath = nextStepId && theTicketSections.find(s => s.id === nextStepId)?.path;
  const nextStepFullText = theTicketSections.find(s => s.id === currentSection)?.nextText;

  const nextStepButton =  nextStepPath ? <Link href={ nextStepPath } passHref legacyBehavior><button onClick={() => setLoadingButton(true)} className={styles.btn}>{nextButton}</button></Link> : null

  const previousStepId = theTicketSections.find(s => s.id === currentSection)?.previousSection;
  const previousStepPath = previousStepId && theTicketSections.find(s => s.id === previousStepId)?.path;
  const previousStepButton = previousStepPath ? <Link
    href={ previousStepPath }
    passHref
    onClick={() => setLoadingLink(true)}
    className={styles.back}>Back</Link> : null;

  let premier = false;

  useEffect(() => {
    if (hotelSearchRooms !== undefined && hotelSearchRooms.length > 0) {
      nextStepPath === 'park-tickets'
    }
    if (hotel_slugToContext) {
      theTicketSections[0].nextSection = 'rooms'
      theTicketSections[1].path = `/hotels/${hotel_slugToContext}`
      theTicketSections[2].previousSection = 'rooms'
    }
    setRoomSearchLength(hotelSearchRooms.length)
  }, [hotelSearchRooms, nextStepPath, hotel_slugToContext, theTicketSections])

  return (
    <CartContext.Consumer>
      {({cartAPI, cart}) => {

      // this function manipulates the next step button
      const buttonDisabler =  (route: string, nextStep : JSX.Element | null, hotelSearchRooms: HotelSearchRoom[], cart: CartType, nextStepButton: boolean | undefined) => {

        // length of the room ticket that are in the cart
        const roomTickets = cart.data.tickets.filter(ticket => ticket.tags.includes('rooms'))

        // CRO-1235
        if (roomTickets.length && isCro1235) setHasHotels(true);

        const disabledNextBtnText = 'NEXT STEP';

        // if we are on the hotel page and we have results coming back front the search SHOW NEXT STEP BUTTON, else BUTTON IS DISABLED
        if (route === '/hotels') {
          if (nextStepButton) {
            return nextStep
          }

          return <button disabled className={styles.btn}>{disabledNextBtnText}</button>
        // if we are on the /hotels/[hotels_slug] page and we have selected the same amount of rooms that we have searched for then SHOW NEXT STEP BUTTON, else BUTTON IS DISABLED
        } else if (route === '/hotels/[hotel_slug]') {
          if (hotelSearchRooms !== undefined && hotelSearchRooms.length === roomTickets.length) {
            return nextStep
          }
          return <button disabled className={styles.btn}>{disabledNextBtnText}</button>
        // if we are not on the /hotels or /hotels/[hotels_slug] page then SHOW NEXT STEP BUTTON
        } else return nextStep
      }

      const floatingCartButton = () => {
        const cartButton = <Link href='/shopping-cart'><span className={styles.visuallyHidden}>Number of items in cart: </span>{ cart.meta.total_count }</Link>
        const cartPopUp = <FloatingCartPopUp cart={cart} />
        const roomTicketsLength = cart.data.tickets.filter(ticket => ticket.tags.includes('rooms')).length

        if (showCartPopUp && roomTicketsLength !== roomSearchLength) {
          return cartPopUp
        } else {
          return cartButton
        }
      }

        let containerStyles = `${styles.floatingCart}`;
        if (placement == "top") {
          containerStyles = `${styles.floatingCart} ${styles.floatingCartTop}`;
        } else if (placement == "bottom") {
          containerStyles = `${styles.floatingCart} ${styles.floatingCartBottom}`;
        }
        if (cart?.loading) {
          containerStyles = `${containerStyles} ${styles.loading}`;
        }

        const items = groupCartTicketsByCategoryAndProductAndDate(cart.data.tickets)

        const categorySections: Array<JSX.Element> = []

        let dateFrom = '';
        let dateTo = '';

        cartTicketCategories.forEach(category => {

          const categoryProducts: Array<JSX.Element> = []

          if (Object.getOwnPropertyNames(items[category]).length) {
            // is it a premier hotel?
            if (category === 'rooms') {
              const roomTickets = cart.data.tickets.filter(ticket => ticket.tags.includes('rooms'))

              if (roomTickets[0].data.category === 'Premier') {
                  premier = true;

                  cart.data.tickets.map(ticket => {
                    if(ticket.tags.includes('hotels')){
                        dateFrom = ticket.attributes['5'][0];
                        dateTo =ticket.attributes['42'][0];
                    }
                })
              }
            }

            if (category === currentSection || currentSectionCatgory === category || isCro1235) {

              if (category === 'rooms') {

                const roomTickets = cart.data.tickets.filter(ticket => ticket.tags.includes('rooms'))
                const key = roomTickets.map(t => t.cart_item_id).join("-")

                categoryProducts.push(
                  <div key={ key } className={ cart.loading ? styles.rowItem : styles.rowItemHide }>
                    <div className={styles.rooms}>
                      <div className={styles.roomsTitle}>{ roomTickets[0].data.attraction_title } { removeLink(cartAPI, roomTickets.map(t => t.ticket_id)) }</div>
                      {roomTickets.map((ticket, i) => {
                        return (
                          <div key={ ticket.cart_item_id } className={ styles.ticketDate }>
                            <b>Room {i + 1}</b>
                            { cartGuestNamePrularizer(+cart.data.tickets[i].attributes[48], +cart.data.tickets[i].attributes[49], +cart.data.tickets[i].attributes[87])}<br/>
                            { ticket.title }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )

              } else {

                for (const productId in items[category]) {

                  for (const date in items[category][productId]) {

                    const tickets = items[category][productId][date]
                    const key = tickets.map(t => t.cart_item_id).join("-")

                      categoryProducts.push(
                        <div key={ key } className={ cart.loading ? styles.rowItem : styles.rowItemHide }>
                          <div>
                            { tickets[0].title }<br />
                            <div className={ styles.ticketDate }>
                              { tickets[0].date ? format(parseISO(tickets[0].date), 'MMMM d, yyyy') : tickets[0].year }
                            </div>
                            <div className={ styles.qty }>
                              { getQtyString(tickets) }
                            </div>
                          </div>
                          { currentSection === category || currentSectionCatgory === category || isCro1235 ? removeLink(cartAPI, tickets.map(t => t.ticket_id), date) : null }
                        </div>
                      )
                    }
                  }
                }
            }

          }

          categorySections.push(
            <div key={category} className={ `${styles.row} ${currentSection === category ? styles.currentSection : ''}` }>
              { sectionHeader(theTicketSections, category, currentSection, Object.getOwnPropertyNames(items[category]).length > 0 || (category === 'expressPasses' && premier),currentSectionCatgory, setCurrentSectionCatgory, isCro1235, clicked, setClicked ) }
              { categoryProducts }
              {category === 'expressPasses' && premier && currentSection === 'expressPasses' &&
                <div key={ 'express' } className={ cart.loading ? styles.rowItem : styles.rowItemHide }>
                  <div>
                    FREE Universal Express Unlimited ride access<br />
                    <div className={ styles.ticketDate }>
                      Universal Studios Florida and Universal Islands of Adventure
                    </div>
                    <div className={ styles.ticketDate }>
                      {format(new Date(dateFrom),'EEE LLL d')} - {format(new Date(dateTo),'EEE LLL d')}
                    </div>
                  </div>
                </div>
              }
            </div>
          )

        })

        const subTotal = cart.meta.total_price ? <b>&pound;{ parseInt(cart.meta.total_price) }</b> : ''

        return (
          <div className={ containerStyles}>
            <div className={ styles.cart }>
              <div className={ styles.header }>
                <span className={ styles.subtotal }>
                  Subtotal: { subTotal }
                </span>
                <span className={ styles.cartNum }>
                  { floatingCartButton() }
                </span>
              </div>
              <div className={ styles.rowContainer }>
                { categorySections }
              </div>
            </div>
            <div className={`${styles.cartNav} ${currentSection} ${(isCro1235 && hasHotels) && styles.hasHotels}`}>
              { !loadingLink ? previousStepButton : <div className='loading mh10'></div> }
              <p className={styles.nextText}>{nextStepFullText}</p>
              { !loadingButton ? buttonDisabler(route ,nextStepButton, hotelSearchRooms, cart, showNextStep) : <button disabled className={styles.btn}>Loading...</button> }
            </div>
          </div>
        )
      }}
    </CartContext.Consumer>
  );
}
